import loveSeats from 'assets/images/love-seats.png';
import premiumSeats from 'assets/images/premium-hall.png';

export const tooltipInfo = {
  Premium: {
    img: premiumSeats,
    label: '_PremiumHall_',
    description: '_PremiumSeatDescription_',
  },
  'Screen 6': {
    img: loveSeats,
    label: '_LoveSeats_',
    description: '_LoveSeatDescription_',
  },
};

export const V_POS = 'vPos';
export const IDRAM = 'idram';
export const TELCELL = 'telcell';
export const FAST_SHIFT = 'fastshift';

export const PAYMENT_METHODS = [
  { name: V_POS, title: '_Card_' },
  { name: IDRAM },
  // { name: TELCELL },
  // { name: FAST_SHIFT },
];
